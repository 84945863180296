clientApp.modules.helpers = (function () {
    'use strict';

    var hasAttr = function (e, attrName) {
        if(e.length){
            var attrVal = e.attr(attrName);
            if (typeof attrVal !== typeof undefined && attrVal !== false && attrVal.length) {
                return true;
            }
        }
        return false;
    };

    var parseYoutubeID = function (url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return false;
        }
    };

    return {
        hasAttr: hasAttr,
        parseYoutubeID: parseYoutubeID,
    };

}());
