clientApp.modules.forms = (function () {
    'use strict';

    var init = function () {
        var pageUrlElement = $(".pageurl");
        if (pageUrlElement.length) {
            var inputElement = pageUrlElement.find("input");
            inputElement.val(window.location.href);
        }
    }

    return {
        init: init
    };

}());

clientApp.modules.forms.init();
