clientApp.modules.hp = (function () {
    'use strict';

    var init = function () {
        if($('.bulletin-list--unpublished .icons-link-list').length){
            $('.bulletin-list--unpublished .icons-link-list').slick({
                //infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {

                    breakpoint: 544,
                    settings: "unslick" // destroys slick

                }]
            });
            $('.slick-fake-btn--left').on('click', function(){
                $('.slick-prev.slick-arrow').trigger('click');
            });
            $('.slick-fake-btn--right').on('click', function () {
                $('.slick-next.slick-arrow').trigger('click');
            });

            if ($('.slick-arrow').length) {
                $('.slick-fake-btn').removeClass('hidden-xs-up');
            }

            if (clientApp.modules.generalResize.getBootstrapMediaQuery() == 'xs') {

            }
        }
        $('.slick-slide').show();
        resize();
    };

    $(window).on('resize', function () {
        resize();
    });

    var resize = function () {
        if ($('.bulletin-list--hp').length) {
            var $latest = $('.bulletin-list--latest');
            var $unpublished = $('.bulletin-list--unpublished');
            if (clientApp.modules.generalResize.getBootstrapMediaQuery() == 'xs') {
                if ($latest.length && !$latest.hasClass('moved')) {
                    $latest.addClass('moved').insertBefore($unpublished);
                }
            } else if ($latest.length && $latest.hasClass('moved')) {
                $latest.removeClass('moved').insertAfter($unpublished);
            }
        }
    };

    return {
        init: init
    };

}());

clientApp.modules.hp.init();
