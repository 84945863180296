clientApp.modules.searchbar = (function () {
    'use strict';

    var searchInputSelector = "#SearchTerm";
    var searched = false;

    var init = function () {
        resize();
        hideMobileFilters();
    };

    $(window).on('load', function () {
        googleTranslateElementInit();
    });

    $(window).on('resize', function () {
        resize();
    });

    $('.search-component-wrap [type="submit"]').on('click', function () {
        searched = true;
    });

    $("select.search-dropdown").on("change", function (e) { 
        $('.search-filter-apply').removeClass('disabled');
        $('.search-result-list-wrap').addClass('apply-opacity');

        var $this = $(this);
        var selectedItems = $(':selected', $this);
        if (selectedItems.length > 0 && selectedItems[0].value === 'All') {
            $this.selectpicker('deselectAll');
            $this.selectpicker('toggle');
        }
    });

    $('.search-filter-apply').on('click', function (e) {
        e.preventDefault();
        search();
    });

    var search = function () {
        var queryString = '';
        var url = window.location.origin + window.location.pathname;

        if (searchData.termToSearch !== '') {
            queryString += "?term=" + searchData.termToSearch;
        }

        var dataQuery = {
            year: [],
            type: [],
            category: [],
            controllers: [],
            series: [],
            models: [],
            region: [],
            language: [],
            bulletintype: [],
            other: []
        };

        $('select.search-dropdown :selected').each(function (index, element) {
            var parentName = element.parentNode.name;
            var value = element.value;

            /*if (parentName !== "other") {
                value = element.innerText;
            } else {
                value = element.value;
            }*/

            if (dataQuery[parentName] !== undefined) {
                dataQuery[parentName].push(value);
            }
        });

        for (var p in dataQuery) {

            (dataQuery[p].filter(function (x) { return (x === 'All')}).length > 0) && (dataQuery[p] = []);

            if (dataQuery[p].length !== 0) {
                if (queryString === '') {
                    queryString += "?" + p + "=" + dataQuery[p].join(';');
                } else {
                    queryString += "&" + p + "=" + dataQuery[p].join(';');
                }
            }
        }

        url += queryString;
        location.href = url;
    };
    
    var resize = function () {
        var $sb = $('.search-component-wrap');
        var mediaQuery = clientApp.modules.generalResize.getBootstrapMediaQuery();
        if ($sb.length) {
            changeBgForMediaQuery($sb, mediaQuery);
        }
        //if not sm or xs show filters
        if ($('.search-filter-wrap').length && $.inArray(
            clientApp.modules.generalResize.getBootstrapMediaQuery(),
            ['xs', 'sm']) == -1) {
            $('#collapsing-filter-bar').collapse('show');
        }
    }
    
    var hideMobileFilters = function () {
        //if sm or xs trigger click on filter
        if ($('.search-filter-wrap').length && $.inArray(
            clientApp.modules.generalResize.getBootstrapMediaQuery(),
            ['xs']) !== -1) {
            $('.search-filter-wrap [data-target="#collapsing-filter-bar"]').trigger('click');
        }
    };

    var changeBgForMediaQuery = function (c, mq) {
        if (clientApp.modules.helpers.hasAttr(c, 'data-desktop-img') && mq != 'xs') {
            c.css('background-image', 'url(' + c.attr('data-desktop-img') + ')');
        }
        if (clientApp.modules.helpers.hasAttr(c, 'data-handset-img') && mq == 'xs') {
            c.css('background-image', 'url(' + c.attr('data-handset-img') + ')');
        }
    }

    var googleTranslateElementInit = function () {
        var searchElementId = 'google_translate_element_desktop';
        if (clientApp.modules.generalResize.getBootstrapMediaQuery() != 'lg') {
            searchElementId = 'google_translate_element_mobile';
        }
        if ($('#' + searchElementId).length) {
            new google.translate.TranslateElement({ 
                pageLanguage: 'en',
                includedLanguages: 'en,es,fr,pt,ru',
                layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                gaTrack: true,
                gaId: waratahGAId
                }, searchElementId);
        }
    }

    return {
        init: init,
        googleTranslateElementInit : googleTranslateElementInit
    };

}());

clientApp.modules.searchbar.init();