clientApp.modules.user = (function () {
    'use strict';

    var init = function() {
        $("#changeDetailsButton").click(function (e) {
            e.preventDefault();
            $("#changeDetailsButton").prop("disabled", true);

            var postUserName = $("#UserName").val();
            var postEmail = $("#Email").val();
            var postCompany = $("#Company").val();
            var postRegion = $("#Region").val();
            var postLanguage = $("#Language").val();
            $.ajax({
                url: "/umbraco/surface/members/ChangeDetails/",
                type: "POST",
                dataType: "json",
                data: "{ UserName: '" + postUserName + "', Email: '" + postEmail + "', Company: '" + postCompany + "', Region: '" + postRegion + "', Language: '" + postLanguage + "' }",
                contentType: "application/json; charset=utf-8",
                success: function (data) {
                    if (data.success) {
                        $("#changeDetailsButton").prop("disabled", false);
                        $("#changeDetailsMessage").removeClass();
                        $("#changeDetailsMessage").addClass("alert alert-success");
                        $("#changeDetailsMessage").html("<strong>" + data.message + "</strong>");
                    } else {
                        $("#changeDetailsButton").prop("disabled", false);
                        $("#changeDetailsMessage").removeClass();
                        $("#changeDetailsMessage").addClass("alert alert-warning");
                        $("#changeDetailsMessage").html("<strong>" + data.message + "</strong>");
                    }
                },
                error: function (data) {
                    $("#changeDetailsButton").prop("disabled", false);
                    $("#changeDetailsMessage").removeClass();
                    $("#changeDetailsMessage").addClass("alert alert-warning");
                    $("#changeDetailsMessage").html("<strong>Error!</strong>");
                }
            });
        });

        $("#changePasswordButton").click(function (e) {
            e.preventDefault();
            $("#changePasswordButton").prop("disabled", true);

            var postUserName = $("#UserName").val();
            var postPassword = $("#Password").val();
            var postConfirmPassword = $("#ConfirmPassword").val();
            $.ajax({
                url: "/umbraco/surface/members/ChangePassword/",
                type: "POST",
                dataType: "json",
                data: "{ UserName: '" + postUserName + "', Password: '" + postPassword + "', ConfirmPassword: '" + postConfirmPassword + "' }",
                contentType: "application/json; charset=utf-8",
                success: function (data) {
                    if (data.success) {
                        $("#Password").val("");
                        $("#ConfirmPassword").val("");
                        $("#changePasswordMessage").removeClass();
                        $("#changePasswordMessage").addClass("alert alert-success");
                        $("#changePasswordMessage").html("<strong>" + data.message + "</strong>");
                    } else {
                        $("#changePasswordButton").prop("disabled", false);
                        $("#changePasswordMessage").removeClass();
                        $("#changePasswordMessage").addClass("alert alert-warning");
                        $("#changePasswordMessage").html("<strong>" + data.message + "</strong>");
                    }
                },
                error: function (data) {
                    $("#changePasswordButton").prop("disabled", false);
                    $("#changePasswordMessage").removeClass();
                    $("#changePasswordMessage").addClass("alert alert-warning");
                    $("#changePasswordMessage").html("<strong>Error!</strong>");
                }
            });
        });

        $("#forgottenPasswordButton").click(function (e) {
            e.preventDefault();
            $("#forgottenPasswordButton").prop("disabled", true);

            var postEmail = $("#Email").val();
            $.ajax({
                url: "/umbraco/surface/members/ForgottenPassword/",
                type: "POST",
                dataType: "json",
                data: "{ Email: '" + postEmail + "' }",
                contentType: "application/json; charset=utf-8",
                success: function (data) {
                    if (data.success) {
                        $("#forgottenPasswordMessage").removeClass();
                        $("#forgottenPasswordMessage").addClass("alert alert-success");
                        $("#forgottenPasswordMessage").html("<strong>" + data.message + "</strong>");
                    } else {
                        $("#forgottenPasswordButton").prop("disabled", false);
                        $("#forgottenPasswordMessage").removeClass();
                        $("#forgottenPasswordMessage").addClass("alert alert-warning");
                        $("#forgottenPasswordMessage").html("<strong>" + data.message + "</strong>");
                    }
                },
                error: function (data) {
                    $("#forgottenPasswordButton").prop("disabled", false);
                    $("#forgottenPasswordMessage").removeClass();
                    $("#forgottenPasswordMessage").addClass("alert alert-warning");
                    $("#forgottenPasswordMessage").html("<strong>Error!</strong>");
                }
            });
        });

        $("#setPasswordButton").click(function (e) {
            e.preventDefault();
            $("#setPasswordButton").prop("disabled", true);

            var postId = $("#Id").val();
            var postToken = $("#Token").val();
            var postPassword = $("#Password").val();
            var postConfirmPassword = $("#ConfirmPassword").val();
            $.ajax({
                url: "/umbraco/surface/members/SetPassword/",
                type: "POST",
                dataType: "json",
                data: "{ Id: '" + postId + "', Token: '" + postToken + "', Password: '" + postPassword + "', ConfirmPassword: '" + postConfirmPassword + "' }",
                contentType: "application/json; charset=utf-8",
                success: function (data) {
                    if (data.success) {
                        $("#Password").val("");
                        $("#setPasswordMessage").val("");
                        $("#setPasswordMessage").removeClass();
                        $("#setPasswordMessage").addClass("alert alert-success");
                        $("#setPasswordMessage").html("<strong>" + data.message + "</strong>");
                    } else {
                        $("#setPasswordButton").prop("disabled", false);
                        $("#setPasswordMessage").removeClass();
                        $("#setPasswordMessage").addClass("alert alert-warning");
                        $("#setPasswordMessage").html("<strong>" + data.message + "</strong>");
                    }
                },
                error: function (data) {
                    $("#setPasswordButton").prop("disabled", false);
                    $("#setPasswordMessage").removeClass();
                    $("#setPasswordMessage").addClass("alert alert-warning");
                    $("#setPasswordMessage").html("<strong>Error!</strong>");
                }
            });
        });
    }

    return {
        init: init
    };

}());

clientApp.modules.user.init();
