clientApp.modules.responsiveTables = (function () {
    'use strict';

    var init = function () {
        makeResponsive();
        resize();
    };

    var makeResponsive = function () {
        $(".rte table").each(function (index) {
            $(this).addClass('table').wrapAll("<div class='responsive-table-wrap'><div class='responsive-table-container'></div></div>")
        });
    }

    var resize = function () {
        var $e = $(".responsive-table-container");
        if ($e.length) {
            $e.each(function () {
                var $t = $(this);
                var $w = $t.parents('.responsive-table-wrap').first();
                //tables
                if ($w.length) {
                    $w.removeClass('has-scroll');
                    if ($t.find('table').length &&
                        $t[0].scrollWidth > $t.width() // compare scroll width and width
                        ) {
                        $w.addClass('has-scroll');
                    }
                }

            });
        }
    }

    $(window).on('resize', function () {
        resize();
    });
    

    return {
        init: init
    };

}());

clientApp.modules.responsiveTables.init();