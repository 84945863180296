clientApp.modules.rte = (function () {
    'use strict';

    var init = function () {
        resize();
        initPopupLinks();
    };

    $(window).on('resize', function () {
        resize();
    });

    var initPopupLinks = function () {
        var youtubeUrl = "https://www.youtube.com/watch?v=";
        var youtubeImagePre = "http://img.youtube.com/vi/";
        var youtubeImageSuf = "/hqdefault.jpg";
        var $e = $('.rte img, .rte iframe');
        if ($e.length) {
            $e.each(function () {
                var $t = $(this);
                if ($t.parents('.contour').length < 1) {
                    var isImage = $t.is('img');
                    var captionText = ($t.next('p').hasClass('caption')) ? ' title="'+$t.next('p.caption').text()+'"' : "";
                    if (isImage) {
                        $t.wrap('<a href="' + $t.attr('src') + '" class="mfp-image popup-link" ' + captionText + '/>');
                        $('<i class="fa fa-arrows-alt"></i>').insertAfter($t);
                    } else {
                        var isYoutube = ($t.is('iframe')) ? clientApp.modules.helpers.parseYoutubeID($t.attr('src')) : false;
                        if (isYoutube.length && $t.parents('.video-wrapper').length) {
                            var newHtml = ('<div><a href="' + youtubeUrl + isYoutube + '" class="mfp-iframe popup-link"><img class="img-fluid force-full-strech" src="' + youtubeImagePre + isYoutube + youtubeImageSuf + '" /><i class="fa fa-play"></i></a></div>');
                            $(newHtml).insertAfter($t.parents('.video-wrapper'));
                            $t.parents('.video-wrapper').remove();
                        }
                    }
                    
                }
            });
            $('.popup-link').magnificPopup({
                callbacks: {
                    open: function () {
                        //dataLayer.push({
                        //    'event': this.currItem.type == 'image' ? 'View Image' : 'View Video',
                        //    'url': this.currItem.src
                        //});
                    }
                }
            });
        }

    };

    var resize = function () {
        if ($('.rte').length) {
            $('.rte').each(function () {
                var chIframe = $(this).find('iframe');
                if (chIframe.length && !chIframe.hasClass('embed-responsive-item')) {
                    chIframe.addClass('embed-responsive-item').wrap('<div class="embed-responsive embed-responsive-4by3"></div>')
                }
            });
            $('.rte img').each(function () {
                if (!$(this).hasClass('img-fluid')) {
                    $(this).addClass('img-fluid');
                }
            });
        }
    };

    return {
        init: init
    };

}());

clientApp.modules.rte.init();