clientApp.modules.maps = (function () {
    'use strict';

    var init = function() {
        var latitude = $("#map").data("latitude");
        var longitude = $("#map").data("longitude");
        var zoom = $("#map").data("zoom");
        var searchtype = $("#map").data("search-type");

        var uluru = { lat: latitude, lng: longitude };

        var map = new google.maps.Map(document.getElementById("map"), {
            zoom: zoom,
            center: uluru
        });
        var marker = new google.maps.Marker({
            position: uluru,
            map: map
        });
    }

    return {
        init: init
    };
}());