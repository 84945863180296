clientApp.modules.truncateText = (function () {
    'use strict';

    var truncateTextClass = 'truncateText';
    var textTruncated = false;

    var init = function () {
        truncateText();
    };

    var truncateText = function () {
        $('.' + truncateTextClass).each(function () {
            var $this = $(this);

            // get line count from current breakpoint
            var attrName = 'data-lines-' + clientApp.modules.generalResize.getBootstrapMediaQuery();
            // if not found, then just go home
            if (!$this[0].hasAttribute(attrName) || parseInt($this.attr(attrName)) < 1) {
                $this.trigger("destroy");
                return;
            }
            var lineCount = parseInt($this.attr(attrName));
            var lineHeight = $this.css('line-height');

            // treat line height values
            if (lineHeight.toLowerCase().indexOf('px') > -1) {
                lineHeight = parseInt(lineHeight, 10);
            } else if (lineHeight.indexOf('%') > -1) {
                lineHeight = (parseInt(lineHeight, 10) / 100) * parseInt($this.css('font-size'), 10);
            } else {
                lineHeight = parseInt(lineHeight, 10) * parseInt($this.css('font-size'), 10);
            }

            // apply parameters
            $this.dotdotdot({
                height: (lineCount * lineHeight) + lineHeight / 3,
                watch: true,
                wrap: 'letter'
            });

        });
        textTruncated = true;
    }

    var resize = function () {
    }

    $(window).on('resize', function () {
        resize();
    });


    return {
        init: init
    };

}());

clientApp.modules.truncateText.init();

