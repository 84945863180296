clientApp.modules.generalResize = (function () {
    'use strict';

    var init = function () {
        resize();
    };

    $(window).on('resize', function () {
        resize();
        
    });

    var getBootstrapVisibleMediaQuery = function() {
        var boostrapBreakpoints = ['lg-down', 'md-down', 'sm-down', 'xs-down'];
        var query = 'xs';
        $('body').append('<div id="bootstrap-media-checker-wrap" style="position: fixed;bottom:0;right:0;"></div>');
        $.each(boostrapBreakpoints, function (k, v) {
            $('#bootstrap-media-checker-wrap').append('<div class="hidden-' + v + '" style="width:1px;height:1px;background:transparent"></div>');
            if ($('#bootstrap-media-checker-wrap .hidden-' + v ).is(':visible')) {
                $('#bootstrap-media-checker-wrap').remove();
                query = v.replace('-down','');
                return false;
            }
        });
        return query;
    }


    var resize = function () {
        console.log('resized to '+getBootstrapVisibleMediaQuery());
    }

    return {
        init: init,
        getBootstrapMediaQuery: getBootstrapVisibleMediaQuery
    };

}());

clientApp.modules.generalResize.init();